import axios from "axios";
import apiClient from "../utils/apiClient";

export const getAllFlows = async () => {
  try {
    const response = await apiClient().get(`call_flows/`);

    return response.data;
  } catch (error) {
    console.error("Error while getting all flows data", error);
    //return error
  }
};

export const getFlowById = async (id) => {
  try {
    const response = await apiClient().get(
      `call_flows/call_flow_detail/${id}/`
    );
    return response;
  } catch (error) {
    console.error("Error while getting flow by Id", error);
    return error.response;
  }
};

export const getFlowsByWorkspaceId = async (workspace_id) => {
  try {
    const response = await apiClient().get(
      `call_flows/get_all_by_workspace_id/${workspace_id}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting all flows by workspace_id", error);
    //return error
  }
};

const giveBackendUrlByParams = (params) => {
  return `${process.env.REACT_APP_QA_TELESERVER_URL}${params}/`;
};

export const makeCallQA = async (data, backend, custom_backend_url) => {
  let backend_url;

  if (backend) {
    backend_url = giveBackendUrlByParams(backend);
  } else if (custom_backend_url && custom_backend_url.trim()) {
    if (custom_backend_url.endsWith("/")) {
      backend_url = custom_backend_url;
    } else {
      backend_url = custom_backend_url + "/";
    }
  } else {
    backend_url = `${process.env.REACT_APP_QA_TELESERVER_URL}server5/`;
  }
  try {
    const response = await axios.post(`${backend_url}make_call_v3`, data);

    return response;
  } catch (error) {
    console.error("Error while make call", error);
    //return error
  }
};

export const makeCallProd = async (id, data) => {
  try {
    const response = await apiClient().post(
      `call_flows/make_call/${id}/`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error while make call", error);
    //return error
  }
};
